import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { ProcessComponent } from './process/process.component';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
// Import BrowserAnimationsModule
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RecaptchaModule } from 'angular-google-recaptcha';
// Import your library
import { AlertModule } from 'ngx-alerts';
import { CareersComponent } from './careers/careers.component';
import { KeyPeoplesComponent } from './key-peoples/key-peoples.component';
import { OurClientsComponent } from './our-clients/our-clients.component';
import { OurProjectsComponent } from './our-projects/our-projects.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ContactusComponent } from './contactus/contactus.component';
import { WhoweareComponent } from './whoweare/whoweare.component';
const appRoutes: Routes = [
  { path: 'process', component: ProcessComponent },
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'careers', component: CareersComponent },
  { path: 'key-people', component: KeyPeoplesComponent },
  { path: 'our-clients', component: OurClientsComponent },
  { path: 'our-projects', component: OurProjectsComponent },
  { path: 'contactus', component: ContactusComponent },
  { path: 'who-we-are', component: WhoweareComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    FooterComponent,

    HomeComponent,
    ProcessComponent,
    CareersComponent,
    KeyPeoplesComponent,
    OurClientsComponent,
    OurProjectsComponent,
    ContactusComponent,
    WhoweareComponent
  ],
  imports: [
    NgxSpinnerModule,
    RecaptchaModule.forRoot({
      siteKey: '6LdGx50UAAAAAG-mJt_vZlhQKVJdBDngoWfxrNIk',
    }),
    BrowserAnimationsModule,
    // Specify your library as an import
    AlertModule.forRoot({ maxMessages: 5, timeout: 5000, position: 'left' }),
    ReactiveFormsModule,
    HttpClientModule,
    HttpModule,
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: false
        //useHash: false
      }
    ),
    BrowserModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
