import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../_services/authentication.service';
import { AlertService } from 'ngx-alerts';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ViewChild, ElementRef } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.css']
})
export class CareersComponent implements OnInit {
  jobs: any;

  isValidFormSubmitted: boolean = null;
  photovalid: boolean;
  Image: any;
  fileName: any;
  job_id: any;
  invalid: boolean;
  public isExpanded = false;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  @ViewChild('submitButton') submitButton: ElementRef;
  @ViewChild("fileInput") fileInputVariable: any;
  activeIndex: number = 0;
  myForm = new FormGroup({
    Portfolio: new FormControl(''),
    LinkedIn: new FormControl(''),
    Location: new FormControl(''),
    CoverLetter: new FormControl(''),
    Email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
    Phone: new FormControl('', [Validators.required]),
    Name: new FormControl('', [Validators.required]),
    Aboutjob: new FormControl('', [Validators.required]),
  });


  constructor(private spinner: NgxSpinnerService, private _fb: FormBuilder, private alertService: AlertService, private formBuilder: FormBuilder, private http: HttpClient, private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.isValidFormSubmitted = false;
    //  this.myForm.reset();
    this.spinner.show();
    this.authenticationService.loadjobs()
      .subscribe(
        data => {


          this.jobs = data['jobs']
          this.spinner.hide();

        },
        error => {

        });
  }

  get f() { return this.myForm.controls; }

  post() {
    /** photo**/
    this.activeIndex = this.job_id;
    this.isValidFormSubmitted = true;
    if (!this.Image) {
      this.photovalid = true; return;
    }
    if (this.myForm.invalid) {
      return;
    }
    this.submitButton.nativeElement.disabled = true;
    this.authenticationService.submitresume(this.myForm.value, this.Image, this.job_id)
      .subscribe(
        data => {
          window.scroll(0, 0);
          this.myForm.reset();
          this.Image = '';
          this.ngOnInit();
          this.activeIndex = null;
          this.alertService.success('Thank you for your interest in TRIVAND.');

        },
        error => { }
      );




  }
  
  clear(id) {

    this.Image = '';
    this.job_id = id;
    this.fileName = '';
    this.activeIndex = id;
    this.myForm.reset();
    const elementList = document.querySelectorAll('.job' + id);
    const element = elementList[0] as HTMLElement;
    element.scrollIntoView({ behavior: 'smooth' });

  }
  public fileUploader(event) {

    const elem = event.target;
    // console.log(event.target.files[0].type);
    if (event.target.files[0] != null) {
      if (event.target.files[0].type != 'application/pdf' && event.target.files[0].type != 'application/msword' && event.target.files[0].type != 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {

        this.invalid = true;
        return;
      }
      this.spinner.show();


      if (elem.files.length > 0) {
        this.photovalid = false;
        this.isValidFormSubmitted = false;
        this.authenticationService.fileupload(elem.files[0])
          .subscribe(
            data => {
              this.spinner.hide();
              this.Image = data['Image'];
              this.invalid = false;
              this.fileName = event.target.files[0].name;
            },
            error => { }
          );

        //console.log(elem.files[0]);
      }
    }
    // ...
  }
}
