import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../_services/authentication.service';
import { AlertService } from 'ngx-alerts';
import { FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  myRecaptcha = new FormControl(false);
  isShow: boolean;
  topPosToStartShowing = 100;


  constructor(private spinner: NgxSpinnerService,private alertService: AlertService, private formBuilder: FormBuilder, private http: HttpClient, private authenticationService: AuthenticationService) {
    this.loadScripts();
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      Name: ['', Validators.required],
     // recaptchaReactive: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      website: ['', Validators.required],
      phoneno: ['', Validators.required],
      company: ['', Validators.required],
      letshear: ['', Validators.required]
    });
  }
  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.spinner.show();
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      this.spinner.hide();
      return;
    }


    this.authenticationService.contact(this.registerForm.value)
      .subscribe(
        data => {
          this.spinner.hide();
        //  console.log(data);
          this.alertService.success('Thanks for contacting us! We will be in touch with you shortly.');
        },
        error => {

        });



    //  console.log(this.registerForm.value);

    //alert('SUCCESS!! :-)')
  }
  onScriptLoad() {
    console.log('Google reCAPTCHA loaded and is ready for use!')
  }

  onScriptError() {
    console.log('Something went long when loading the Google reCAPTCHA')
  }



  loadScripts() {
    const dynamicScripts = [
    //  'assets/js/jquery.min.js',
     // 'assets/js/bootstrap.min.js',
      'assets/js/jquery-touchswipe-min.js',
      'assets/js/bootstrap-slider.js',
      'assets/js/main.js',
      'assets/js/aos.js',
      'assets/js/hc-offcanvas-nav.js',
      'assets/js/extra.js',
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }
  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

 
    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  // TODO: Cross browsing
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }


}
