import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-key-peoples',
  templateUrl: './key-peoples.component.html',
  styleUrls: ['./key-peoples.component.css']
})
export class KeyPeoplesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
