import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Router } from "@angular/router";
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
@Injectable({
    providedIn: 'root'
})

export class AuthenticationService {
    urlPrefix: string;
    constructor(private router: Router, private http: Http) {
        this.urlPrefix = 'http://zesline.com/trivand/admin/api/';
     }

    contact(form: string) {
        return this.http.post(this.urlPrefix+'contacttrivand', JSON.stringify({ form }))
            .map((response: Response) => {
                return response.json();
            });
    }
    loadjobs() {
        return this.http.get(this.urlPrefix+'loadjobs')
            .map((response: Response) => {
                return response.json();
            });

    }

    fileupload(file) {
        let formData: FormData = new FormData();
        if (file) {
            formData.append('file', file, file.name);
          }
          return this.http.post(this.urlPrefix+'fileupload', formData)
            .map((response: Response) => {
              // console.log(response.json().name);
      
              return response.json();
            });


      

    }
    submitresume(data, doc: string,job_id:string) {
        //console.log(value);
        return this.http.post(this.urlPrefix+'submitresume', JSON.stringify({ doc: doc, data: data,job_id:job_id }))
            .map((response: Response) => {
                return response.json();
            });
    }

}